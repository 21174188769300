import { useEffect, useState } from 'react'
import { useReactOidc } from '@axa-fr/react-oidc-context'
import { Button, Card, Label, Notification, Spinner } from '@myeh/design-system'
import classnames from 'classnames'

import { getContactById } from '../../../services/ContactService'
import { PageTitle } from '../../atoms/PageTitle/PageTitle'
import AdditionalContactInformation, {
  dropdownEmptyValue
} from '../../molecules/AdditionalContactInformation/AdditionalContactInformation'
import Datatable from '../../molecules/Datatable/Datatable'
import InfoMessage from '../../molecules/InfoMessage/InfoMessage'
import SearchBar from '../../molecules/SearchBar/SearchBar'

import style from './ActAsHomePage.module.scss'

const ActAsHomePage = () => {
  const [{ status, data }, setSearchData] = useState({ status: null, data: {} })
  const [searchQuery, setSearchQuery] = useState(null)
  const [buCode, setBuCode] = useState(dropdownEmptyValue)
  const [userType, setUserType] = useState(dropdownEmptyValue)

  const { oidcUser } = useReactOidc()

  useEffect(() => {
    const getContactDetails = async () => {
      setSearchData({ status: null, data: {} })
      try {
        const { status, data } = await getContactById(searchQuery, oidcUser)
        setSearchData({ status, data })
      } catch (error) {
        setSearchData({ status: error?.response?.status || 500 })
      }
    }
    searchQuery && getContactDetails()
  }, [searchQuery, oidcUser])

  // /contact/:idcontact returns a 404 error if the contact is not found
  const hasNoResults = [204, 404].includes(status)
  const hasResults = status === 200
  const hasError = ![200, 204, 404, null].includes(status)
  const isUserTypeValid = userType !== dropdownEmptyValue

  // columns definition for DataTable
  const columns = [
    {
      Header: 'Contact',
      accessor: 'contactId'
    },
    {
      Header: 'Name',
      accessor: 'firstName'
    },
    {
      Header: 'Surname',
      accessor: 'lastName'
    },
    {
      Header: 'Email address',
      accessor: 'businessEmail'
    }
  ]

  return (
    <>
      <PageTitle title='Act as' />
      <div className='pa4'>
        <SearchBar setSearchQuery={setSearchQuery} />
        {searchQuery && !status && (
          <div className={style.spinner}>
            <Spinner size='large' />
          </div>
        )}
        {status && (
          <div className='mt4'>
            <Label className='subtitle'>Result</Label>
          </div>
        )}
        {(hasError || hasNoResults) && (
          <div>
            <Card data-testid='act-as-result-information' className={style.information}>
              <InfoMessage isErrorMessage={hasError} />
            </Card>
          </div>
        )}
        {/* we are keeping the Datatable but for now but we have only one result */}
        {hasResults && (
          <div>
            <Datatable data={[data]} columns={columns} />
            {data?.contactId && (
              <>
                <AdditionalContactInformation
                  buCode={buCode}
                  setBuCode={setBuCode}
                  userType={userType}
                  setUserType={setUserType}
                  className='mt3'
                />
                {!isUserTypeValid && (
                  <Notification context='warning' data-testid='warning-userType'>
                    You have to select a userType to proceed
                  </Notification>
                )}
                <div className={classnames(style.submit, { mt3: !isUserTypeValid })}>
                  <Button
                    variant='primary'
                    size='small-medium'
                    data-testid='myeh-link'
                    disabled={!isUserTypeValid}
                    onClick={() => {
                      const buCodeParam = buCode && buCode !== dropdownEmptyValue ? `&bu-code=${buCode}` : ''
                      const userTypeParam = `&user-type=${userType}`
                      window.location.href = `${process.env.REACT_APP_MYEH_LINK}?xx-act-as=${data.contactId}${buCodeParam}${userTypeParam}`
                    }}
                  >
                    Start Act-as session
                  </Button>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default ActAsHomePage
